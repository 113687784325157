.raw-data-export-type-body {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 50px;
    display: flex;
    justify-content: center;

    .export-type-btn {
        width: 242px !important;
        height: 60px !important;
        font-size: 15px !important;
    }

    .export-type-btn-sub {
        width: 242px !important;
        height: 40px !important;
        font-size: 11px !important;

    }

    .raw-data-export-type-container {
        max-width: 800px;
        width: 100%;

        .export-type-title {
            font-weight: 700;
            font-size: 18px;
            margin-bottom: 20px;
            font-family: 'Lato', sans-serif;
        }

        .raw-data-export-type-items {
            display: flex;
            gap: 36px;
            flex-wrap: wrap;

            .raw-data-export-type-item {
                display: flex;
                flex-direction: column;

                .raw-data-export-type-subitems {
                    margin-top: 15px;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }
            }
        }
    }
}