.rise-header {
    
    img {
        height: 60px;
        margin-top: 10px;
    }
    .rise-user-round {
        width: 40px;
        height: 40px;
        background-color: #2A3EF3;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 12px;
    }
    .rise-user-info {
        display: flex;
        font-size: 18px;
        line-height: 40px;
        p {
            margin-left: 5px;
        }
    }
}