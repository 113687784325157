.notAuthed-message-box {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    .login-text {
        font-size: 18px;
        line-height: 18px;
        font-weight: 400;
        font-family: 'Roboto', sans-serif;
    }

    .login-btn {
        border: 1px solid #DDDDDD;
        box-sizing: border-box;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 250px;
        order: 0;
        padding: 20px;
        flex-grow: 0;
        cursor: pointer;
        margin-top: 20px;
        background: rgb(230 227 255 / 58%);
    }
}