.rise-report-body {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    .rise-report-content {
        display: flex;
        flex-direction: column;
    }
    .info-text {
        font-size: 13px;
        font-family: 'Lato', sans-serif;
    }
    .report-error-range {
        color: #D01010;
        font-size: 12px;
        font-family: 'Lato', sans-serif;
    }
}
